import React from 'react'

import './blankPage.sass'


function BlankPage() {
  return (
    <div className='blank'>
      <h1 className="blank_title">ООО «Инженерное бюро Цаммит»</h1>
      <h2 className="blank_text">Идёт реконструкция сайта. Скоро всё заработает.</h2>
    </div>
  )
}

export default BlankPage