import { ADMIN_ROUTE, MAIN_ROUTE, PROJECT_ROUTE, SERVISES_ROUTE, SYSTEMS_ROUTE, VACANCY_ROUTE, BLANK_ROUTE } from "./utils/consts";
import { AdminPage, MainPage, ProjectsPage, ServisesPage, SystemsPage, VacancyPage, BlankPage } from './pages';

export const authRoutes = [
    {
        path: ADMIN_ROUTE,
        Component: AdminPage
    }
];

export const publicRoutes = [
    {
        path: BLANK_ROUTE,
        Component: BlankPage
        // path: MAIN_ROUTE,
        // Component: MainPage
    },
    // {
    //     path: PROJECT_ROUTE,
    //     Component: ProjectsPage
    // },
    // {
    //     path: SERVISES_ROUTE,
    //     Component: ServisesPage
    // },
    // {
    //     path: SYSTEMS_ROUTE,
    //     Component: SystemsPage
    // },
    // {
    //     path: VACANCY_ROUTE,
    //     Component: VacancyPage
    // }
];